import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pt_BR from "../locale/pt_BR.json";
import en_US from "../locale/en_US.json";
import Cookies from "js-cookie";

const resources = {
  "pt-BR": pt_BR,
  "en-US": en_US,
};

const lng = Cookies.get("default_language");

i18n.use(initReactI18next).init({
  resources,
  lng: lng ?? "pt-BR",
});

export default i18n;
